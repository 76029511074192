import React from 'react'
import { Link } from 'react-router-dom'

export default function header() {
  return (
   <>
		<div class="sk__mobile-menu-bar"></div>


		<a class="sk__mobile-main-logo" href="/"><img alt="Website logo." src="assets/images/logo-name.png" /></a>

		<nav id="main-nav" style={{opacity: 0}} class="sk__menu navbar sk__navbar navbar-expand-lg navbar-dark static-top">

			<a class="navbar-brand" href="/"><img id="sk__main-logo" alt="Website logo." src="assets/images/logo-name.png" /></a>

			<ul class="navbar-nav ms-auto">

				<li data-nav-custom-content class="custom-content sk__mobile-menu-logo"> 
					<a class="sk__mobile-navbar-brand" href="/"><h3 style={{fontWeight: '900'}}><img alt="Website mobile logo." src="assets/images/brand.png" />REVANOID</h3></a>
				</li>
				
				<li class="nav-item menu-item-has-children">
					<li class="nav-item"><a class="nav-link" href="/"><span class="sk__menu-icon"><span class="icon-codepen1"></span></span>Home</a></li>
					<li class="nav-item"><a class="nav-link" href="/services"><span class="sk__menu-icon"><span class="icon-th"></span></span>Services</a></li>
					<li class="nav-item"><a class="nav-link" href="/template"><span class="sk__menu-icon"><span class="icon-menu"></span></span>Templates</a></li>
				</li>

				
				<li class="nav-item menu-item-has-children">
					<a class="nav-link hvr-underline-from-center" ><span class="sk__menu-icon"><span class="icon-th"></span></span>Pages</a> 
					<ul class="sk__submenu-ul">
						<li class="nav-item"><Link class="nav-link" to="/web-design"><span class="sk__menu-icon"><span class="icon-codepen1"></span></span>Web Design</Link></li>
						<li class="nav-item"><a class="nav-link" href="/e-commerce"><span class="sk__menu-icon"><span class="icon-codepen1"></span></span>E-commerce</a></li>
						<li class="nav-item"><a class="nav-link" href="/admin-panel"><span class="sk__menu-icon"><span class="icon-codepen1"></span></span>Admin Panels</a></li>
						<li class="nav-item"><a class="nav-link" href="/seo"><span class="sk__menu-icon"><span class="icon-codepen1"></span></span>SEO</a></li>
						<li class="nav-item"><a class="nav-link" href="/digital-marketing"><span class="sk__menu-icon"><span class="icon-codepen1"></span></span>Digital Marketing</a></li>
						<li class="nav-item"><a class="nav-link" href="/logo-making"><span class="sk__menu-icon"><span class="icon-codepen1"></span></span>Logo Making</a></li>
					</ul>
				</li>

					{/* <!-- <li class="nav-item"><a class="nav-link" href="template.php"><span class="sk__menu-icon"><span class="icon-th"></span></span>Templates</a></li> -->
					<!-- <li class="nav-item"><a class="nav-link" href="portfolio.php"><span class="sk__menu-icon"><span class="icon-th"></span></span>Portfolio</a></li> --> */}
					<li class="nav-item"><a class="nav-link" href="/about"><span class="sk__menu-icon"><span class="icon-user-circle"></span></span>About Us</a></li>
					<li class="nav-item"><a class="nav-link" href="/contact"><span class="sk__menu-icon"><span class="icon-envelope-o"></span></span>Contact Us</a></li>
		

			
				<li data-nav-custom-content class="custom-content sk__menu-socials">
					<section class="footer-socials-section">
						<h3><strong>Connect</strong> with us</h3>
						<div class="footer-socials-inner">
							<div class="footer-socials">
								{/* <!-- <a class="social-icons" href="https://twitter.com/Revanoid_office/" target="_blank" rel="noreferrer"><span><span class="icon-twitter1"></span></span></a> -->
								<!-- <a class="social-icons" href="https://www.facebook.com/profile.php?id=61551992468097" target="_blank" rel="noreferrer"><span><span class="icon-facebook1"></span></span></a> --> */}
								<a class="social-icons" href="https://www.instagram.com/revanoid._/" target="_blank" rel="noreferrer"><span><span class="icon-instagram1"></span></span></a>
								<a class="social-icons" href="https://wa.link/6i6uku" target="_blank" rel="noreferrer"><span><span class="icon-whatsapp1"></span></span></a>
								<a class="social-icons" href="https://www.linkedin.com/company/revanoid/" target="_blank" rel="noreferrer"><span><span class="icon-linkedin1"></span></span></a>
								<a class="social-icons" href="https://youtu.be/AfqMJOfHd8U?si=g9aRN300eI3JboJE" target="_blank" rel="noreferrer"><span><span class="icon-youtube1"></span></span></a>
							</div>
						</div>
					</section>
				</li>

			</ul>
		</nav>
		</>
		)
}
