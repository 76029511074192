import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header';

import Home from './pages/index'; 
import Webdesign from './pages/web-design';
// import Seo from './pages/seo'

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/web-design" element={<Webdesign />} />
        {/* <Route path="/contact-us" element={<Seo />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
