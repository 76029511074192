
import React from 'react'
import Footer from '../components/footer';
import { Link } from 'react-router-dom';


export default function Webdesign() {
  return (
<div class="sk__404-page dark-shade-1-bg static-simple-footer">

	<main id="primary" class="site-main">

		{/* <!-- Back to top button --> */}
		<div class="sk__back-to-top-wrap">
			<a class="sk__back-to-top" href="#smooth-content"><span class="sk__back-to-top"></span></a>
		</div>

	

		
		<div id="smooth-wrapper" class="pushable-content">
			<div id="smooth-content">

				{/* <!-- Hero Social Icons Menu --> */}
				<section class="hero-socials-section">
					<div class="hero-socials-inner">
						<div class="hero-socials">
							<Link class="social-icons" to="https://www.facebook.com/SkilltechWebDesign" target="_blank" rel="noreferrer"><span><span class="icon-youtube1"></span></span></Link>
							<a class="social-icons" href="https://www.facebook.com/SkilltechWebDesign" target="_blank" rel="noreferrer"><span><span class="icon-twitter1"></span></span></a>
							<a class="social-icons" href="https://www.facebook.com/SkilltechWebDesign" target="_blank" rel="noreferrer"><span><span class="icon-behance1"></span></span></a>
							<a class="social-icons" href="https://www.facebook.com/SkilltechWebDesign" target="_blank" rel="noreferrer"><span><span class="icon-dribbble1"></span></span></a>
							<a class="social-icons" href="https://www.facebook.com/SkilltechWebDesign" target="_blank" rel="noreferrer"><span><span class="icon-instagram1"></span></span></a>
							<a class="social-icons" href="https://www.facebook.com/SkilltechWebDesign" target="_blank" rel="noreferrer"><span><span class="icon-pinterest"></span></span></a>
						</div>
					</div>
				</section>

			
				{/* <!-- Header Section
				================================================== --> */}
				<section class="sk__parallax-header sk__animated-header sk__header-y-l sk__service-header dark-shade-5-border">
					<div class="sk__parallax-header-image sk__absolute sk__image-back-cover sk__filter-city" style={{backgroundImage: `url("assets/images/website/hero.html")`}}></div>
					<div class="sk__tint-light sk__absolute"></div>

					<div class="container sk__supercontainer">
						<div class="row text-center">
							<div class="col-12">
								<h1>Awesome Landing Page</h1>
								<h2 class="h2-regular thin mb-1">A beautiful <strong>Service or Product</strong> Presentation Page.</h2>
							</div>
						</div>
					</div>

				</section>
				{/* <!-- /.sk__service-header --> */}


			


				{/* <!-- Skills Section
				================================================== --> */}
				<section id="skills" class="sk__skills sk__py-m">

					<div class="container sk__supercontainer">

						{/* <!-- Section Header --> */}
						<div class="row sk__heading-spacer-l">
							<div class="col-12 col-xl-10 offset-xl-1 text-center">
								<h1 class="h1-small mb-3 pt-4 pb-1">How can this service help you</h1>
								<p class="p-v2 skills-subhead">Our web application development service empowers your business by creating dynamic and user-friendly digital solutions that captivate your audience. With our proven track record, you can trust us to turn your vision into reality, drive customer satisfaction, and boost your online presence. Don't miss the opportunity to elevate your brand and stay ahead of the competition.</p>
							</div>
						</div>

						<div class="row sk__skills-row">
							<div class="col-12">
								<div class="sk__skill-wrap">
									<p class="sk__skill-name sk__skill-el">Improves Online Image</p>
									<div class="sk__skill-area sk__skill-el">
										<div class="sk__skill-bar sk__gradient-back-v1"></div>
									</div>
									<p class="sk__skill-number sk__skill-el"><span class="sk__counterskills">95</span><span>%</span></p>
								</div>
								<div class="sk__skill-wrap">
									<p class="sk__skill-name sk__skill-el">Speeds up Your Website</p>
									<div class="sk__skill-area sk__skill-el">
										<div class="sk__skill-bar sk__gradient-back-v1"></div>
									</div>
									<p class="sk__skill-number sk__skill-el"><span class="sk__counterskills">80</span><span>%</span></p>
								</div>
								<div class="sk__skill-wrap">
									<p class="sk__skill-name sk__skill-el">Doubles Conversions</p>
									<div class="sk__skill-area sk__skill-el">
										<div class="sk__skill-bar sk__gradient-back-v1"></div>
									</div>
									<p class="sk__skill-number sk__skill-el"><span class="sk__counterskills">70</span><span>%</span></p>
								</div>
								<div class="sk__skill-wrap">
									<p class="sk__skill-name sk__skill-el">Design Quality</p>
									<div class="sk__skill-area sk__skill-el">
										<div class="sk__skill-bar sk__gradient-back-v1"></div>
									</div>
									<p class="sk__skill-number sk__skill-el"><span class="sk__counterskills">97</span><span>%</span></p>
								</div>
								<div class="sk__skill-wrap">
									<p class="sk__skill-name sk__skill-el">Increases Revenue</p>
									<div class="sk__skill-area sk__skill-el">
										<div class="sk__skill-bar sk__gradient-back-v1"></div>
									</div>
									<p class="sk__skill-number sk__skill-el"><span class="sk__counterskills">90</span><span>%</span></p>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col sk__features-subtext mt-4 mt-md-3">
								<div class="features-subtext-deco"></div>
								<p class="p-xs text-center text-sm-end features-subtext-p">We guarantee 100% client satisfaction. <br/>
									Our commitment is unwavering. <br/>
									Your happiness is our priority.</p>
								</div>
						</div>

						<div class="row mt-5">
							<div class="col text-center text-sm-start">
								<h4 class="h4-dark h4-shadow">See our works.</h4>
								<span class="fat-divider dark-shade-4-bg"></span>
							</div>
						</div>

						<div class="row mt-5">
							<div class="col-12 col-xl-8">

								{/* <!-- Basic Gallery Slider --> */}
								<section class="sk__gallery-basic">

									{/* <!-- Carousel --> */}
									<div id="sk__service-1-gallery-basic" class="carousel slide">
								
										{/* <!-- Dots --> */}
										<div class="carousel-indicators">
											<button type="button" data-bs-target="#sk__service-1-gallery-basic" data-bs-slide-to="0" class="active"></button>
											<button type="button" data-bs-target="#sk__service-1-gallery-basic" data-bs-slide-to="1"></button>
											<button type="button" data-bs-target="#sk__service-1-gallery-basic" data-bs-slide-to="2"></button>
											<button type="button" data-bs-target="#sk__service-1-gallery-basic" data-bs-slide-to="3"></button>
											<button type="button" data-bs-target="#sk__service-1-gallery-basic" data-bs-slide-to="4"></button>
										</div>
								
										{/* <!-- Slides --> */}
										<div class="carousel-inner">
											<div class="carousel-item active">
												<img src="assets/images/website/1.html" alt="service-01-gallery-01"/>
												<div class="carousel-caption">
													{/* <!-- <div class="carousel-caption-description">
														<p>Basic design for CAKE SHOP.</p>
													</div> --> */}
												</div>
											</div>
											<div class="carousel-item">
												<img src="assets/images/website/2.html" alt="service-01-gallery-02"/>
												<div class="carousel-caption">
													{/* <!-- <div class="carousel-caption-description">
														<p>Basic design for COFFEE SHOP.</p>
													</div> --> */}
												</div>
											</div>
											<div class="carousel-item">
												<img src="assets/images/website/3.html" alt="service-01-gallery-03"/>
												<div class="carousel-caption">
													{/* <!-- <div class="carousel-caption-description">
														<p>Basic design for HOTELS.</p>
													</div> --> */}
												</div>
											</div>
											<div class="carousel-item">
												<img src="assets/images/website/4.html" alt="service-01-gallery-04"/>
												<div class="carousel-caption">
													{/* <!-- <div class="carousel-caption-description">
														<p>Basic design for EDUCATION.</p>
													</div> --> */}
												</div>
											</div>
											<div class="carousel-item">
												<img src="assets/images/website/5.html" alt="service-01-gallery-05"/>
												<div class="carousel-caption">
												</div>
											</div>
										</div>
								
										{/* <!-- Arrows --> */}
										<button class="carousel-control-prev" data-bs-target="#sk__service-1-gallery-basic" data-bs-slide="prev">
											<span class="carousel-control-prev-icon" aria-hidden="true"></span>
											<span class="visually-hidden">Previous</span>
										</button>
										<button class="carousel-control-next" data-bs-target="#sk__service-1-gallery-basic" data-bs-slide="next">
											<span class="carousel-control-next-icon" aria-hidden="true"></span>
											<span class="visually-hidden">Next</span>
										</button>
								
									</div>
									{/* <!-- /#sk__service-1-gallery-basic --> */}

								</section>
								{/* <!-- /.sk__gallery-basic --> */}

							</div>
							<div class="col-12 col-xl-4 sk__project-body-info-col mt-5 mt-xl-0 text-center text-sm-start">

								<h5>creative Web-Design</h5>
								<p style={{textAlign: 'justify'}}>Elevate your brand with our creative web design expertise, where every pixel tells a story of innovation and style. Let your website be a visual masterpiece that captivates your audience.</p>
								<a class="btn btn-outline-light" href="template.php" role="button">View Templates -&gt;</a>
								<div class="row">
									<div class="col-12 col-sm-6">
										<h5>There are no boundaries</h5>
										<p class="p-xs" style={{textAlign: 'justify'}}>There are no boundaries when it comes to the possibilities our web applications can unlock for your business.</p>
									</div>
									<div class="col-12 col-sm-6">
										<h5>Expand your business</h5>
										<p class="p-xs" style={{textAlign: 'justify'}}> Expand your reach, connect with a global audience, and achieve limitless success. With our expertise, your digital ambitions know no limits.</p>
									</div>
								</div>
								
							</div>
						</div>		
						
					</div>

				</section>
				{/* <!-- /.sk__skills --> */}


				<section class="sk__deco-row-section">
					<div class="container-fluid">
						<div class="row sk__deco-row dark-shade-7-bg dark-shade-5-border"></div>
					</div>
				</section>


				{/* <!-- CTA Section (Warp CTA)
				================================================== --> */}
				<section class="sk__cta-warp position-relative sk__image-back-cover">
					<div class="container sk__powercontainer">
						<div class="row">
							<div class="col text-center">
								<div class="sk__warped-text-wrapper sk__flex-center">
									<span class="sk__warped-text">GET START NOW</span>
								</div>
								<h3><strong>HAVE A PROFESSIONAL TOUCH</strong></h3>
								<a class="btn btn-lg btn-outline-light sk__warped-button" href="contact-us.php" role="button">Contact Us -&gt;</a>
							</div>
						</div>
					</div>
				</section>

	{/* <!-- Features Section
				================================================== --> */}
				<section class="sk__features-section sk__fade-in-10 sk__py-s dark-shade-3-bg text-center text-sm-start">
					<div class="container sk__supercontainer">
						<div class="row sk__heading-spacer-s">
							<div class="col">
								<h4 class="h4-shadow">Packages.</h4>
								<span class="fat-divider dark-shade-4-bg"></span>
							</div>
						</div>
						<div class="row sk__features delayed">
							<div class="col-md-4 sk__feature-col">
								<div class="sk__feature">
									<h5>Basic</h5>
									<div class="colorline-deco">
										<div class="colorline-deco-normal sk__absolute"></div>
										<div class="colorline-deco-hover sk__absolute sk__gradient-back-v1"></div>
									</div>
									<p>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;upto 5 Page</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Custom Design</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Social Media Integration</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Blog Page</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Dynamic</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;1 Email Account</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Free Domain</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Delicated Account Manager</li>
									<a class="btn btn-lg btn-outline-light gradient-links" href="contact-us.php" role="button">Rs 5,500 -&gt;</a>
									</p>
								</div>
							</div>
							<div class="col-md-4 sk__feature-col">
								<div class="sk__feature">
									<h5>Standard.</h5>
									<div class="colorline-deco">
										<div class="colorline-deco-normal sk__absolute"></div>
										<div class="colorline-deco-hover sk__absolute sk__gradient-back-v1"></div>
									</div>
									<p>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;upto 10 Page</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Custom Design</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Payment Gateway</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;User Added Pages</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Google Analytic Setup</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;5 Email Account</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Google Optimized Content</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;+basic plan Features</li>
									<a class="btn btn-lg btn-outline-light gradient-links" href="contact-us.php" role="button">Rs 9,500 -&gt;</a>
									</p>
								</div>
							</div>
							<div class="col-md-4 sk__feature-col">
								<div class="sk__feature">
									<h5>Enterprise.</h5>
									<div class="colorline-deco">
										<div class="colorline-deco-normal sk__absolute"></div>
										<div class="colorline-deco-hover sk__absolute sk__gradient-back-v1"></div>
									</div>
									<p>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;upto 15 Page</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Animation Design</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Unlimited photos & videos</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Content Management</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Market keyword Research</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;100 Email Account</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;Schema Markup Optimization</li>
									<li class="gradient-links" style={{cursor: 'grab'}}>-&gt;&nbsp;&nbsp;&nbsp;+Standard Plan Features</li>
									<a class="btn btn-lg btn-outline-light gradient-links"  href="contact-us.php" role="button">Rs 14,000 -&gt;</a>
									</p>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col sk__features-subtext">
								<div class="features-subtext-deco"></div>
								<p class="p-xs text-center text-sm-end features-subtext-p">We guarantee 100% client satisfaction. <br/>
									Our commitment is unwavering. <br/>
									Your happiness is our priority.</p>
							</div>
						</div>
					</div>
				</section>
				{/* <!-- /.sk__features-section --> */}
				<Footer />
				<div class="sk__body-end"></div>

			</div>
			{/* <!-- /#smooth-content --> */}

		</div>
		{/* <!-- /#smooth-wrapper --> */}

	</main>
	{/* <!-- /main#primary.site-main --> */}



</div>
)
}
