
import React from 'react'
import Footer from '../components/footer';
export default function Home() {
  return (

<div class="sk__404-page dark-shade-1-bg static-simple-footer"> 







	<main id="primary" class="site-main">

		{/* <!-- Master Curtain Effect --> */}
		<section class="sk__master-curtain">
			<div class="mcurtain mcurtain-left"></div>
			<div class="mcurtain mcurtain-center"></div>
			<div class="mcurtain mcurtain-right"></div>
		</section>

		{/* <!-- Back to top button --> */}
		<div class="sk__back-to-top-wrap">
			<a class="sk__back-to-top" href="#smooth-content"><span class="sk__back-to-top"></span></a>
		</div>

		{/* <!-- Header --> */}

	


		<div id="smooth-wrapper" class="pushable-content">
			<div id="smooth-content">

		{/* <!-- Hero Social Icons Menu --> */}
			<section class="hero-socials-section">
				<div class="hero-socials-inner">
					<div class="hero-socials">
						<a class="social-icons" href="https://youtu.be/AfqMJOfHd8U?si=g9aRN300eI3JboJE" target="_blank" rel="noopener noreferrer">
							<span><span class="icon-youtube1"></span></span>
						</a>
						<a class="social-icons" href="https://twitter.com/Revanoid_" target="_blank" rel="noopener noreferrer">
							<span><span class="icon-twitter1"></span></span>
						</a>
						<a class="social-icons" href="https://www.facebook.com/profile.php?id=61551992468097" target="_blank" rel="noopener noreferrer">
							<span><span class="icon-facebook1"></span></span>
						</a>
						<a class="social-icons" href="https://www.linkedin.com/company/revanoid/" target="_blank" rel="noopener noreferrer">
							<span><span class="icon-linkedin1"></span></span>
						</a>
						<a class="social-icons" href="https://www.instagram.com/revanoid._/" target="_blank" rel="noopener noreferrer">
							<span><span class="icon-instagram1"></span></span>
						</a>
						<a class="social-icons" href="https://wa.link/6i6uku" target="_blank" rel="noopener noreferrer">
							<span><span class="icon-whatsapp1"></span></span>
						</a>
					</div>
				</div>
			</section>

			
				{/* <!-- Hero Section / Hero Slider
				================================================== --> */}
				<section class="sk__hero-section">
					{/* <!-- Carousel --> */}
					<div id="sk__hero-carousel-slider" class="carousel slide dark-shade-1-bg">
				
						{/* <!-- Hero Dots Navigation Bootstrap 5 --> */}
						<div class="carousel-indicators">
							<button type="button" data-bs-target="#sk__hero-carousel-slider" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
							<button type="button" data-bs-target="#sk__hero-carousel-slider" data-bs-slide-to="1" aria-label="Slide 2"></button>
							<button type="button" data-bs-target="#sk__hero-carousel-slider" data-bs-slide-to="2" aria-label="Slide 3"></button>
							<button type="button" data-bs-target="#sk__hero-carousel-slider" data-bs-slide-to="3" aria-label="Slide 4"></button>
						</div>
				
						{/* <!-- Slides --> */}
						<div class="carousel-inner">

							{/* <!-- Slide 1 --> */}
							<div class="carousel-item zooming active hero-slide-1 sk__hero-slider-item sk__image-back-cover">

							<section class="sk__parallax-background-section sk__hero-item-theme-style">

								{/* <!-- Parallax background --> */}
								<div class="sk__parallax-background-element sk__absolute sk__image-back-cover" style={{backgroundImage: `url("assets/images/hero/hero-1.jpg")`}} alt="Your Descriptive Alt Text"></div>

								<div class="flex-helper-div"></div>

								{/* <!-- Main hero heading --> */}
								<div class="hero-h1-box">
									<div class="cover-text-wrapper">
										<h1 class="hero-h1 animated-element phase-1 text-center text-md-start text-white">Revolutionizing Your <br/> Digital Presence</h1>
									</div>
								</div>

								{/* <!-- Bottom Left box --> */}
								<div class="hero-box-bottom-left text-center text-sm-start">

									<a class="btn btn-outline-light animated-element phase-1 mb-4" href="#about-us" role="button">Discover Our Services</a>

									<div class="cover-text-wrapper">
										<h3 class="animated-element phase-1 text-center text-sm-start text-white">Leading the Way in Web and Mobile App Development</h3>
									</div>

									<div class="cover-text-wrapper">
										<p class="hero-box-p animated-element phase-1 text-center text-sm-start text-white">Empower your business with innovative web and mobile solutions tailored for dynamic markets.</p>
									</div>
								</div>

								{/* <!-- Bottom Right Box --> */}
								<div class="hero-box-bottom-right text-center text-sm-start">
									<div class="cover-text-wrapper">
										<span class="big-abbreviated-heading animated-element phase-2 text-white">Design</span>
									</div>
									<div class="cover-text-wrapper">
										<p class="animated-element phase-2 text-white">Crafting exceptional, responsive, and mobile-friendly websites for businesses.</p>
									</div>
									<div class="cover-text-wrapper">
										<h4 class="animated-element phase-2 text-white">Elevate Your Online Presence</h4>
									</div>
								</div>

							</section>
							</div>


							{/* <!-- /.hero-slide-1 --> */}

							{/* <!-- Slide 2 --> */}
							<div class="carousel-item zooming hero-slide-2 sk__hero-slider-item sk__image-back-cover">

    <section class="sk__video-background-section sk__hero-item-theme-style">

        {/* <!-- Video background (commented out for now) -->
        <!-- <div class="sk__video-container sk__parallax-hero-video sk__absolute sk__image-back-cover">
            <div class="sk__pattern-overlay" style="opacity: 37%;"></div>
            <div class="sk__absolute sk__gradient-back-v1" style="opacity: 0;"></div>
            <video class="sk__video" preload="none" poster="assets/video/hero-video-main.html" autoplay="" loop="" muted="" playsinline="">
                <source src="assets/video/hero-video-main.mp4" type="video/mp4">Your browser does not support HTML5 video.
            </video>
        </div> --> */}

        {/* <!-- Parallax background --> */}
        <div class="sk__parallax-background-element sk__absolute sk__image-back-cover" style={{backgroundImage: `url("assets/images/hero/hero-2.jpg")`}} alt="Innovation, Inclusivity, Sustainability"></div>

        <div class="flex-helper-div"></div>

        {/* <!-- Main hero heading --> */}
        <div class="hero-h1-box">
            <div class="cover-text-wrapper">
                <h1 class="hero-h1 animated-element phase-1 text-center text-md-start text-white">Innovation<br /> Inclusivity<br /> Sustainability</h1>
            </div>
        </div>

        {/* <!-- Bottom Left box --> */}
        <div class="hero-box-bottom-left text-center text-sm-start">

            <a class="btn btn-outline-light animated-element phase-1 mb-4" href="services.php" role="button">View Services</a>

            <div class="cover-text-wrapper">
                <h3 class="animated-element phase-1 text-center text-sm-start text-white">Embracing Design Evolution</h3>
            </div>

            <div class="cover-text-wrapper">
                <p class="hero-box-p animated-element phase-1 text-center text-sm-start text-white">Transforming Ideas into Stunning Digital Experiences.</p>
            </div>
        </div>

        {/* <!-- Bottom Right Box --> */}
        <div class="hero-box-bottom-right text-center text-sm-start">
            <div class="cover-text-wrapper">
                <span class="big-abbreviated-heading animated-element phase-2 text-white">Vision</span>
            </div>
            <div class="cover-text-wrapper">
                <p class="animated-element phase-2 text-white">Where Creativity Meets Cutting-Edge Technology</p>
            </div>
            <div class="cover-text-wrapper">
                <h4 class="animated-element phase-2 text-white">Our Expertise</h4>
            </div>
        </div>

    </section>
</div>

							{/* <!-- /.hero-slide-2 --> */}

							{/* <!-- Slide 3 --> */}
							<div class="carousel-item zooming hero-slide-3 sk__hero-slider-item sk__image-back-cover"> 
    <section class="sk__parallax-background-section sk__hero-item-theme-style">
        
        {/* <!-- Parallax background --> */}
        <div class="sk__parallax-background-element sk__absolute sk__image-back-cover" style={{backgroundImage: `url("assets/images/hero/hero-3.jpg")`}} alt="Premium Effects Included"></div>

        <div class="flex-helper-div"></div>
        
        {/* <!-- Main hero heading --> */}
        <div class="hero-h1-box">
            <div class="cover-text-wrapper">
                <h1 class="hero-h1 animated-element phase-1 text-center text-md-start text-white">Premium<br /> Effects<br /> Included</h1>
            </div>
        </div>

        {/* <!-- Bottom Left box --> */}
        <div class="hero-box-bottom-left text-center text-sm-start">

            <a class="btn btn-outline-light animated-element phase-1 mb-4" href="services.php" role="button">View Services</a>
            
            <div class="cover-text-wrapper">
                <h3 class="animated-element phase-1 text-center text-sm-start text-white">Align and <strong>Affordable</strong></h3>
            </div>

            <div class="cover-text-wrapper">
                <p class="hero-box-p animated-element phase-1 text-center text-sm-start text-white">Our lean approach delivers high-value web development within startup-friendly budgets.</p>
            </div>
        </div>

        {/* <!-- Bottom Right Box --> */}
        <div class="hero-box-bottom-right text-center text-sm-start">
            <div class="cover-text-wrapper">
                <span class="big-abbreviated-heading animated-element phase-2 text-white">Launch</span>
            </div>
            <div class="cover-text-wrapper">
                <p class="animated-element phase-2 text-white">Count on us for fast, reliable, and scalable web solutions.</p>
            </div>
            <div class="cover-text-wrapper">
                <h4 class="animated-element phase-2 text-white">With Confidence</h4>
            </div>
        </div>

    </section>
</div>

							{/* <!-- /.hero-slide-3 --> */}

							{/* <!-- Slide 4 --> */}
							<div class="carousel-item zooming hero-slide-4 sk__hero-slider-item sk__image-back-cover">
    <section class="sk__parallax-background-section sk__hero-item-center-center">
        <div class="sk__parallax-background-element sk__absolute sk__image-back-cover" style={{backgroundImage: `url("assets/images/hero/hero-4.jpg")`}} alt="SEO Services"></div>

        {/* <!-- Hero content --> */}
        <div class="mb-2 text-center">
            <span class="animated-element phase-1"></span>
            <span class="animated-element phase-1"></span>
            <div class="cover-text-wrapper">
                <h1 class="hero-h1 animated-element phase-1">SEARCH ENGINE OPTIMIZATION</h1>
            </div>
            <div class="cover-text-wrapper">
                <h2 class="h2-regular thin animated-element phase-1">Boosting your online visibility with expert SEO strategies, one click at a time.</h2>
            </div>
            <div class="cover-text-wrapper">
                <a class="btn btn-lg btn-outline-light animated-element phase-1" href="seo.php" role="button">View SEO Services</a>
            </div>
        </div>
    </section>
</div>

							{/* <!-- /.hero-slide-4 --> */}
				
						</div>

						{/* <!-- Arrows Bootstrap 5 --> */}
						<button class="carousel-control-prev" type="button" data-bs-target="#sk__hero-carousel-slider" data-bs-slide="prev" aria-label="Previous">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
</button>
<button class="carousel-control-next" type="button" data-bs-target="#sk__hero-carousel-slider" data-bs-slide="next" aria-label="Next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
</button>


					</div>
					{/* <!-- /#sk__hero-carousel-slider --> */}
				</section>
				{/* <!-- /.sk__hero-section --> */}
				
				<section class="sk__parallax-background-section sk__parallax-fixer-section sk__parallax-fixer-ignore-height overflow-hidden" style={{maxHeight: '0'}}>
					<div class="sk__parallax-background-element"></div>
				</section>



				{/* <!-- Parallax Rings Section (massive step forward)
				================================================== --> */}
				<section class="sk__rings-section sk__image-back-cover dark-shade-1-bg overflow-hidden">

{/* <!-- parallax area --> */}
<div id="sk__parallax-layers-1" class="container sk__full-height position-relative hue-rotator duration-2s">
	{/* <!-- gradient ring 1 --> */}
	<div data-depth="1.20" class="sk__absolute">
		<div class="sk__flex-center ring-l-container sk__absolute">
			<div class="ring-l sk__gradient-back-v1 dark-shade-1-bg-after"></div>
		</div>
	</div>
	{/* <!-- gradient ring 2 --> */}
	<div data-depth="1.10" class="sk__absolute">
		<div class="sk__flex-center ring-m-container sk__absolute">
			<div class="ring-m sk__gradient-back-v1 dark-shade-1-bg-after"></div>
		</div>
	</div>
	{/* <!-- gradient ring 3 --> */}
	<div data-depth="0.90" class="sk__absolute">
		<div class="sk__flex-center ring-s-container sk__absolute">
			<div class="ring-s sk__gradient-back-v1 dark-shade-1-bg-after"></div>
		</div>
	</div>
	{/* <!-- white rectangle deco 1 --> */}
	<div data-depth="1.00" class="sk__absolute">
		<div class="sk__flex-center sk__absolute">
			<div class="sk__rectangles">
				<div class="sk__rectangle-white-1 sk__absolute"></div>
			</div>
		</div>
	</div>
	{/* <!-- white rectangle deco 2 --> */}
	<div data-depth="0.90" class="sk__absolute">
		<div class="sk__flex-center sk__absolute">
			<div class="sk__rectangles">
				<div class="sk__rectangle-white-2 sk__absolute"></div>
			</div>
		</div>
	</div>
	{/* <!-- black rectangle deco --> */}
	<div data-depth="0.75" class="sk__absolute">
		<div class="sk__flex-center sk__absolute">
			<div class="sk__rectangles">
				<div class="sk__rectangle-black sk__absolute"></div>
			</div>
		</div>
	</div>
	{/* <!-- heading & subheading Shadows --> */}
	<div data-depth="0.65" class="sk__absolute">
		<div class="sk__flex-center sk__absolute px-15px">
			<div class="flex-child">
				<h1 class="shadowed super-heading text-center">Code, Create, Connect.</h1>
				<h2 class="shadowed h2-small text-center">Your New Online Presence With REVANOID.</h2>
			</div>
		</div>
	</div>
	{/* <!-- heading & subheading --> */}
	<div data-depth="0.65" class="sk__absolute">
		<div class="sk__flex-center sk__absolute px-15px">
			<div class="flex-child text-center">
				<h1 class="super-heading sk__clipped-text sk__gradient-back-v1">Code, Create, Connect...</h1>
				<h2 class="h2-small">Your New Online Presence With REVANOID.</h2>
			</div>
		</div>
	</div>

</div>
{/* <!-- /#sk__parallax-layers-1 --> */}

</section>

				{/* <!-- /.sk__rings-section --> */}

{/* 
				<!-- Numbers Section (Animated Counters)
				================================================== -->
				<!--<section class="sk__numbers-section dark-shade-3-bg sk__solid-menu-bar">-->
				<!--	<div class="container-fluid">-->
				<!--		<div class="row sk__numbers-row text-center">-->
				<!--			<div class="col-6 col-sm-4 col-md counters-wrap sk__flex-center">-->
				<!--				<div class="flex-child">-->
				<!--					<span class="sk__counter" data-gsap-counter-number="83">0</span>-->
				<!--					<p>Clients</p>-->
				<!--					<span class="numbers-deco sk__absolute"></span>-->
				<!--				</div>-->
				<!--			</div>-->
				<!--			<div class="col-6 col-sm-4 col-md counters-wrap sk__flex-center">-->
				<!--				<div class="flex-child">-->
				<!--					<span class="sk__counter" data-gsap-counter-number="97">0</span>-->
				<!--					<p>Projects</p>-->
				<!--					<span class="numbers-deco sk__absolute"></span>-->
				<!--				</div>-->
				<!--			</div>-->
				<!--			<div class="col-6 col-sm-4 col-md counters-wrap sk__flex-center">-->
				<!--				<div class="flex-child">-->
				<!--					<span class="sk__counter" data-gsap-counter-number="5">0</span>-->
				<!--					<p>Partners</p>-->
				<!--					<span class="numbers-deco sk__absolute"></span>-->
				<!--				</div>-->
				<!--			</div>-->
				<!--			<div class="col-6 col-sm-4 col-md counters-wrap sk__flex-center">-->
				<!--				<div class="flex-child">-->
				<!--					<span class="sk__counter" data-gsap-counter-number="73">0</span>-->
				<!--					<p>website Developed</p>-->
				<!--					<span class="numbers-deco sk__absolute"></span>-->
				<!--				</div>-->
				<!--			</div>-->
				<!--		</div>-->
				<!--	</div>-->
				<!--</section>-->
				<!-- /.sk__numbers-section --> */}


				{/* <!-- About Us Section
				================================================== --> */}
				<section id="about-us" class="sk__about-us-section sk__py-xl">
					<div class="container sk__supercontainer">
						<div class="row">
							<div class="col-12 col-lg-6 sk__rectangles-left about-left text-center text-sm-start">
								{/* <!-- parallax area --> */}
								<div id="sk__parallax-layers-about" class="sk__rectangles-left-parallax-layers">
									{/* <!-- white rectangle deco 1 --> */}
									<div data-depth="1.30" class="sk__absolute">
										<div class="sk__flex-center sk__absolute">
											<div class="sk__rectangles">
												<div class="sk__rectangle-white-1 sk__absolute"></div>
											</div>
										</div>
									</div>
									{/* <!-- white rectangle deco 2 --> */}
									<div data-depth="0.70" class="sk__absolute">
										<div class="sk__flex-center sk__absolute">
											<div class="sk__rectangles">
												<div class="sk__rectangle-white-2 sk__absolute"></div>
											</div>
										</div>
									</div>
									{/* <!-- black rectangle deco --> */}
									<div data-depth="1.00" class="sk__absolute">
										<div class="sk__flex-center sk__absolute">
											<div class="sk__rectangles">
												<div class="sk__rectangle-black sk__absolute"></div>
											</div>
										</div>
									</div>
								</div>
									<h1 class="super-heading sk__clipped-text sk__gradient-back-v1 mb-sm-1">About Revanoid<span class="heading-deco">.-&gt;</span></h1>
										<h2 class="h2-regular">Crafting Digital Experiences, Building Success</h2>
											<p class="mw-440" style={{textAlign: 'justify'}}>
											At Revanoid, we've successfully delivered over 50 websites, ranging from business and eCommerce to web applications. Our industry experience empowers us to create professional, unique, and affordable website designs tailored for startups.
											</p>
								</div>
							<div class="col-12 col-lg-6 about-right">
								<div class="about-right-image-wrap mt-4 mt-lg-0 mb-4 mb-lg-0">
									<div class="about-right-image-subwrap sk__absolute"><div class="about-right-image sk__absolute sk__image-back-cover"></div></div>
								</div>
							</div>
						</div>
						<div class="row pt-4 pb-3">
							<div class="col-12 col-lg-6 col-xl-5 sk__flex-center mb-4 my-lg-3 my-xl-0 px-lg-0 custom-about-number-col">
								<p class="p-xs custom-about-us-p2 text-center text-sm-end mb-4 mb-sm-0" >We guarantee 100% client satisfaction. <br/>
									Our commitment is unwavering. <br/>
									Your happiness is our priority.</p><span class="about-number  text-center text-sm-start">100%</span>
							</div>
							<div class="col-12 col-lg-6 col-xl-7 sk__flex-center custom-about-divider-col">
								<span class="divider"></span>
							</div>
						</div>
						<div class="row row text-center text-sm-start">
							<div class="col-12 col-lg-6 sk__flex about-button-col order-2 order-lg-1">
								<div class="flex-child mb-3 mb-lg-0">
								<a class="btn btn-outline-light mb-4" href="services.php" role="button">Discover Our Services</a>
								</div>
							</div>
							<div class="col-12 col-lg-6 order-1 order-lg-2 mb-4 mb-lg-0">
                <p style={{textAlign: 'justify'}}>
                    Our commitment to 100% client satisfaction is unwavering. Your happiness is our priority. We embrace continuous improvement in quality, cost, and speed. Our approach involves listening to your goals and determining how to deliver them.
                </p>
            </div>
						</div>
						<div class="row mt-2">
							<div class="col">
								<span class="divider"></span>
							</div>
						</div>
					</div>


					{/* <!-- Partners Section
					================================================== --> */}
					<div class="sk__partners-section">
						<div class="container sk__supercontainer">
							<div class="row">
								<div class="col text-center text-sm-start">
									<h4 class="h4-dark h4-shadow">Our Specialties.</h4>
									<span class="fat-divider dark-shade-4-bg"></span>
								</div>
							</div>
							<div class="row sk__partners m-0 d-flex justify-content-center">
								<div class="col-xs-4 col-sm-2">
									<div class="partner partner-1 sk__image-back-cover"></div>
								</div>
								<div class="col-xs-4 col-sm-2">
									<div class="partner partner-2 sk__image-back-cover"></div>
								</div>
								<div class="col-xs-4 col-sm-2">
									<div class="partner partner-3 sk__image-back-cover"></div>
								</div>
								<div class="col-xs-4 col-sm-2">
									<div class="partner partner-4 sk__image-back-cover"></div>
								</div>
								<div class="col-xs-4 col-sm-2">
									<div class="partner partner-5 sk__image-back-cover"></div>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- /.sk__partners-section --> */}
					
				</section>
				{/* <!-- /.sk__about-us-section --> */}


				<section class="sk__deco-row-section">
					<div class="container-fluid">
						<div class="row sk__deco-row dark-shade-7-bg dark-shade-5-border"></div>
					</div>
				</section>



				{/* <!-- Portfolio Section
				================================================== -->
				<!-- <section class="sk__portfolio-section sk__py-l dark-shade-2-bg sk__solid-menu-bar">
					<div class="container-fluid">
						<div class="row text-center">
							<div class="col sk__heading-spacer-l">
								<h1 class="h1-small trigger-portfolio-thumbs-entrance">Portfolio</h1>
							</div>
						</div>
						<div class="row">
							<div class="col sk__portfolio-thumbs px-sm-3 px-md-5">
								<div class="sk__portfolio-wrapper text-center">
									<div class="sk__portfolio-item">
										<a href="page-portfolio-item.html" class="sk__portfolio-thumblink">
											<div class="sk__pt-link-icon">
												<div class="sk__pt-link-icon-ovr-1"></div>
												<div class="sk__pt-link-icon-ovr-2"></div>
											</div>
											<div class="sk__portfolio-thumblink-image sk__image-back-cover" style="background-image: url('assets/images/portfolio-thumbs-01.html');"></div>
											<div class="portfolio-thumb-info">
												<h6>Photography</h6>
												<h4 class="h4-small">Seventh Culture</h4>
												<p>View Project</p>
											</div>
										</a>
									</div>
									<div class="sk__portfolio-item">
										<a href="page-portfolio-item.html" class="sk__portfolio-thumblink">
											<div class="sk__pt-link-icon">
												<div class="sk__pt-link-icon-ovr-1"></div>
												<div class="sk__pt-link-icon-ovr-2"></div>
											</div>
											<div class="sk__portfolio-thumblink-image sk__image-back-cover" style="background-image: url('assets/images/portfolio-thumbs-02.html');"></div>
											<div class="portfolio-thumb-info">
												<h6>Photography</h6>
												<h4 class="h4-small">Charcoal Fashion</h4>
												<p>View Project</p>
											</div>
										</a>
									</div>
									<div class="sk__portfolio-item">
										<a href="page-portfolio-item.html" class="sk__portfolio-thumblink">
											<div class="sk__pt-link-icon">
												<div class="sk__pt-link-icon-ovr-1"></div>
												<div class="sk__pt-link-icon-ovr-2"></div>
											</div>
											<div class="sk__portfolio-thumblink-image sk__image-back-cover" style="background-image: url('assets/images/portfolio-thumbs-03.html');"></div>
											<div class="portfolio-thumb-info">
												<h6>Photography</h6>
												<h4 class="h4-small">Spirited Culture</h4>
												<p>View Project</p>
											</div>
										</a>
									</div>
									<div class="sk__portfolio-item">
										<a href="page-portfolio-item.html" class="sk__portfolio-thumblink">
											<div class="sk__pt-link-icon">
												<div class="sk__pt-link-icon-ovr-1"></div>
												<div class="sk__pt-link-icon-ovr-2"></div>
											</div>
											<div class="sk__portfolio-thumblink-image sk__image-back-cover" style="background-image: url('assets/images/portfolio-thumbs-04.html');"></div>
											<div class="portfolio-thumb-info">
												<h6>Photography</h6>
												<h4 class="h4-small">Blackened Technics</h4>
												<p>View Project</p>
											</div>
										</a>
									</div>
									<div class="sk__portfolio-item">
										<a href="page-portfolio-item.html" class="sk__portfolio-thumblink">
											<div class="sk__pt-link-icon">
												<div class="sk__pt-link-icon-ovr-1"></div>
												<div class="sk__pt-link-icon-ovr-2"></div>
											</div>
											<div class="sk__portfolio-thumblink-image sk__image-back-cover" style="background-image: url('assets/images/portfolio-thumbs-05.html');"></div>
											<div class="portfolio-thumb-info">
												<h6>Photography</h6>
												<h4 class="h4-small">Project Vulcan</h4>
												<p>View Project</p>
											</div>
										</a>
									</div>
									<div class="sk__portfolio-item">
										<a href="page-portfolio-item.html" class="sk__portfolio-thumblink">
											<div class="sk__pt-link-icon">
												<div class="sk__pt-link-icon-ovr-1"></div>
												<div class="sk__pt-link-icon-ovr-2"></div>
											</div>
											<div class="sk__portfolio-thumblink-image sk__image-back-cover" style="background-image: url('assets/images/portfolio-thumbs-06.html');"></div>
											<div class="portfolio-thumb-info">
												<h6>Photography</h6>
												<h4 class="h4-small">Creative Culture</h4>
												<p>View Project</p>
											</div>
										</a>
									</div>
									<div class="sk__portfolio-item">
										<a href="page-portfolio-item.html" class="sk__portfolio-thumblink">
											<div class="sk__pt-link-icon">
												<div class="sk__pt-link-icon-ovr-1"></div>
												<div class="sk__pt-link-icon-ovr-2"></div>
											</div>
											<div class="sk__portfolio-thumblink-image sk__image-back-cover" style="background-image: url('assets/images/portfolio-thumbs-07.html');"></div>
											<div class="portfolio-thumb-info">
												<h6>Photography</h6>
												<h4 class="h4-small">Charcoal Fashion</h4>
												<p>View Project</p>
											</div>
										</a>
									</div>
									<div class="sk__portfolio-item">
										<a href="page-portfolio-item.html" class="sk__portfolio-thumblink">
											<div class="sk__pt-link-icon">
												<div class="sk__pt-link-icon-ovr-1"></div>
												<div class="sk__pt-link-icon-ovr-2"></div>
											</div>
											<div class="sk__portfolio-thumblink-image sk__image-back-cover" style="background-image: url('assets/images/portfolio-thumbs-08.html');"></div>
											<div class="portfolio-thumb-info">
												<h6>Photography</h6>
												<h4 class="h4-small">Fashion & Webdesign</h4>
												<p>View Project</p>
											</div>
										</a>
									</div>
									<div class="sk__portfolio-item">
										<a href="page-portfolio-item.html" class="sk__portfolio-thumblink">
											<div class="sk__pt-link-icon">
												<div class="sk__pt-link-icon-ovr-1"></div>
												<div class="sk__pt-link-icon-ovr-2"></div>
											</div>
											<div class="sk__portfolio-thumblink-image sk__image-back-cover" style="background-image: url('assets/images/portfolio-thumbs-09.html');"></div>
											<div class="portfolio-thumb-info">
												<h6>3D Modeling</h6>
												<h4 class="h4-small">Primer Photography</h4>
												<p>View Project</p>
											</div>
										</a>
									</div>
									<div class="sk__portfolio-item">
										<a href="page-portfolio-item.html" class="sk__portfolio-thumblink">
											<div class="sk__pt-link-icon">
												<div class="sk__pt-link-icon-ovr-1"></div>
												<div class="sk__pt-link-icon-ovr-2"></div>
											</div>
											<div class="sk__portfolio-thumblink-image sk__image-back-cover" style="background-image: url('assets/images/portfolio-thumbs-10.html');"></div>
											<div class="portfolio-thumb-info">
												<h6>Graphic Design</h6>
												<h4 class="h4-small">Vulture Culture</h4>
												<p>Creative Design</p>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> -->
				<!-- /.sk__portfolio-section -/-> */}


				{/* <!-- Featured Project Section
				================================================== --> */}
				<section class="sk__featured-project-section sk__parallax-background-section dark-shade-2-bg sk__flex">
					<div class="sk__featured-project-background sk__parallax-background-element sk__absolute sk__image-back-cover" style={{backgroundImage: `url(assets/images/template.jpg)`}}></div>
					<div class="sk__featured-project-infos-container sk__absolute sk__flex-center-y">
						<div class="featured-project">
							<div class="sk__rectangles-full-left text-center text-sm-start">
								{/* <!-- parallax area --> */}
								<div id="sk__parallax-layers-featured-project" class="sk__rectangles-full-left-parallax-layers">
									{/* <!-- main background deco --> */}
									<div data-depth="0.40" class="sk__rectangles-full-left-main-tint"></div>
									{/* <!-- white rectangle deco 1 --> */}
									<div data-depth="1.30" class="sk__absolute">
										<div class="sk__flex-center sk__absolute">
											<div class="sk__rectangles">
												<div class="sk__rectangle-white-1 sk__absolute"></div>
											</div>
										</div>
									</div>
									{/* <!-- white rectangle deco 2 --> */}
									<div data-depth="0.70" class="sk__absolute">
										<div class="sk__flex-center sk__absolute">
											<div class="sk__rectangles">
												<div class="sk__rectangle-white-2 sk__absolute"></div>
											</div>
										</div>
									</div>
									{/* <!-- black rectangle deco --> */}
									<div data-depth="1.00" class="sk__absolute">
										<div class="sk__flex-center sk__absolute">
											<div class="sk__rectangles">
												<div class="sk__rectangle-black sk__absolute"></div>
											</div>
										</div>
									</div>
								</div>
								<div class="flex-child sk__featured-project-infos sk__solid-menu-bar">
                    <h1 class="super-heading sk__clipped-text sk__gradient-back-v1 mb-sm-1">Premium Templates<span class="heading-deco">.</span></h1>
                    <h2 class="h2-regular">Elevate Your Online Presence with Our Templated Designs</h2>
                    <p class="mw-440" style={{textAlign: 'justify'}}>
                        Explore our premium template collection for a strong foundation in superior SEO performance. Meticulously designed, our templates enhance search engine visibility, providing a significant advantage to your online presence. Elevate your website's SEO potential with our top-tier template selection.
                    </p>
                    <a class="btn btn-outline-light mt-3" href="template.php" role="button">Discover Templates</a>
                </div>
							</div>	
						</div>
					</div>
				</section>
				{/* <!-- /.sk__featured-project-section --> */}

				<div class="dark-shade-2-bg featured-project-padder"></div>

{/* 
				<!-- Features Section
				================================================== --> */}
				<section class="sk__features-section sk__py-s dark-shade-3-bg text-center text-sm-start">
    <div class="container sk__supercontainer">
        <div class="row sk__heading-spacer-s">
            <div class="col">
                <h4 class="h4-shadow">Key Features</h4>
                <span class="fat-divider dark-shade-4-bg"></span>
            </div>
        </div>
        <div class="row sk__features">
            {/* <!-- Feature 1 --> */}
            <div class="col-x1-12 col-sm-6 col-md-4 sk__feature-col">
                <div class="sk__feature">
                    <h5>Unique & Exclusive Offerings</h5>
                    <div class="colorline-deco">
                        <div class="colorline-deco-normal sk__absolute"></div>
                        <div class="colorline-deco-hover sk__absolute sk__gradient-back-v1"></div>
                    </div>
                    <p style={{textAlign: 'justify'}}>Our product line includes unique and exclusive features, like Powerful ERP, One Click Login, Superior Tracking, Ultrafast Servers, Right Technology, and the Perfect process.</p>
                </div>
            </div>
            {/* <!-- Feature 2 --> */}
            <div class="col-x1-12 col-sm-6 col-md-4 sk__feature-col">
                <div class="sk__feature">
                    <h5>Customer-Centric Design</h5>
                    <div class="colorline-deco">
                        <div class="colorline-deco-normal sk__absolute"></div>
                        <div class="colorline-deco-hover sk__absolute sk__gradient-back-v1"></div>
                    </div>
                    <p style={{textAlign: 'justify'}}>Design & simplicity are the core of our website builder. We create, customize & promote a stunning website. Our website builder helps bring your unique vision to life.</p>
                </div>
            </div>
            {/* <!-- Feature 3 --> */}
            <div class="col-x1-12 col-sm-6 col-md-4 sk__feature-col">
                <div class="sk__feature">
                    <h5>Experienced Designers</h5>
                    <div class="colorline-deco">
                        <div class="colorline-deco-normal sk__absolute"></div>
                        <div class="colorline-deco-hover sk__absolute sk__gradient-back-v1"></div>
                    </div>
                    <p style={{textAlign: 'justify'}}>Our proficient designers with extensive exposure to loads of projects will be available at your service as per your convenience. We promise to serve you with agility and, of course, with a human touch.</p>
                </div>
            </div>
            {/* <!-- Feature 4 --> */}
            <div class="col-x1-12 col-sm-6 col-md-4 sk__feature-col">
                <div class="sk__feature">
                    <h5>Competitive Pricing</h5>
                    <div class="colorline-deco">
                        <div class="colorline-deco-normal sk__absolute"></div>
                        <div class="colorline-deco-hover sk__absolute sk__gradient-back-v1"></div>
                    </div>
                    <p style={{textAlign: 'justify'}}>Our segment-based pricing is both competitive and affordable. The advantage for our clients is that they can choose the segment as per their convenience and satisfaction.</p>
                </div>
            </div>
            {/* <!-- Feature 5 --> */}
            <div class="col-x1-12 col-sm-6 col-md-4 sk__feature-col">
                <div class="sk__feature">
                    <h5>Premium Design Services</h5>
                    <div class="colorline-deco">
                        <div class="colorline-deco-normal sk__absolute"></div>
                        <div class="colorline-deco-hover sk__absolute sk__gradient-back-v1"></div>
                    </div>
                    <p style={{textAlign: 'justify'}}>Premium segment offers the freedom to choose from more than hundreds of designs. It caters to the need for high-end designs, out-of-the-box solutions with more robust configurations.</p>
                </div>
            </div>
            {/* <!-- Feature 6 --> */}
            <div class="col-x1-12 col-sm-6 col-md-4 sk__feature-col">
                <div class="sk__feature">
                    <h5>Easy & Convenient Solutions</h5>
                    <div class="colorline-deco">
                        <div class="colorline-deco-normal sk__absolute"></div>
                        <div class="colorline-deco-hover sk__absolute sk__gradient-back-v1"></div>
                    </div>
                    <p style={{textAlign: 'justify'}}>Our service platform offers a variety of options that are easy to adopt. It provides a range of products, services & solutions to go for as per their requirements & convenience.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col sk__features-subtext">
                <div class="features-subtext-deco"></div>
                <p class="p-xs text-center text-sm-end features-subtext-p">
                    We guarantee 100% client satisfaction. <br/>
                    Our commitment is unwavering. <br/>
                    Your happiness is our priority.
                </p>
            </div>
        </div>
    </div>
</section>

				{/* <!-- /.sk__features-section --> */}


				{/* <!-- Laptop Section
				================================================== --> */}
				<section class="sk__laptop-section dark-shade-3-bg">
    <div class="container sk__supercontainer">
        <div class="row">
            <div class="col sk__laptop-infos-container sk__flex-center-y sk__laptop-text-col">
                <div class="sk__laptop-mockup-subcontainer"></div>
                <div class="sk__laptop-infos-subcontainer">
                    <div class="sk__rectangles-laptop">
                        {/* <!-- parallax area --> */}
                        <div id="sk__parallax-layers-laptop" class="sk__rectangles-laptop-parallax-layers">
                            {/* <!-- main background deco --> */}
                            <div data-depth="0.40" class="sk__rectangles-laptop-main-tint"></div>
                            {/* <!-- white rectangle deco 1 --> */}
                            <div data-depth="1.30" class="sk__absolute">
                                <div class="sk__flex-center sk__absolute">
                                    <div class="sk__rectangles">
                                        <div class="sk__rectangle-white-1 sk__absolute"></div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- white rectangle deco 2 --> */}
                            <div data-depth="0.70" class="sk__absolute">
                                <div class="sk__flex-center sk__absolute">
                                    <div class="sk__rectangles">
                                        <div class="sk__rectangle-white-2 sk__absolute"></div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- black rectangle deco --> */}
                            <div data-depth="1.00" class="sk__absolute">
                                <div class="sk__flex-center sk__absolute">
                                    <div class="sk__rectangles">
                                        <div class="sk__rectangle-black sk__absolute"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-child sk__laptop-infos text-center text-sm-end">
                            <h1 class="super-heading sk__clipped-text sk__gradient-back-v1 mb-sm-1"><span class="heading-deco">&lt;- </span>Logo.</h1>
                            <h2 class="h2-regular">Premium <strong>Logo Design</strong> Services</h2>
                            <p class="sk__rectangles-laptop-custom-p mw-440" style={{textAlign: 'justify'}}>When it comes to logo design, our experts combine artistic flair with strategic precision. We craft logos that capture your brand's essence, ensuring your visual identity stands out in online searches. Trust us for logos that are both visually stunning and search engine friendly.</p>
                            <a class="btn btn-outline-light mt-3" href="logo-making.php" role="button">View Logo Designs</a>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</section>

				{/* <!-- /.sk__laptop-section --> */}



				{/* <!-- Services Section
				================================================== --> */}
				<section id="services" class="sk__services-section sk__parallax-background-section dark-shade-5-border sk__solid-menu-bar">
    <div class="sk__parallax-background-element sk__absolute sk__image-back-cover sk__services-background"></div>
    <div class="container sk__py-l">
        <div class="row text-center">
            <div class="col sk__heading-spacer-l">
                <div class="cover-text-wrapper">
                    <h1 class="super-heading sk__clipped-text sk__gradient-back-v1 animated-element">Services.-&gt;</h1>
                </div>
            </div>
        </div>
        <div class="row sk__iconboxes-wrapper text-center sk__flex-center-x">
            {/* <!-- Web Design --> */}
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="sk__iconbox">
                    <a class="sk__iconbox-icon-link" href="web-design.php">
                        <span class="sk__flex-center sk__iconbox-icon"><span class="icon-web sk__gradient-fancy-text"></span></span>
                        <span class="sk__iconbox-icon-dash"></span>
                    </a>
                    <a class="sk__iconbox-text-link gradient-links-bright" href="web-design.php">
                        <h5>Web Design</h5>
                    </a>
                    <p>Our team's proven track record in web design ensures your brand's digital presence will captivate and convert visitors into loyal customers.</p>
                </div>
            </div>
            {/* <!-- SEO --> */}
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="sk__iconbox">
                    <a class="sk__iconbox-icon-link" href="seo.php">
                        <span class="sk__flex-center sk__iconbox-icon"><span class="icon-search sk__gradient-fancy-text"></span></span>
                        <span class="sk__iconbox-icon-dash"></span>
                    </a>
                    <a class="sk__iconbox-text-link gradient-links-bright" href="seo.php">
                        <h5>SEO</h5>
                    </a>
                    <p>Our SEO expertise goes beyond keywords – we're committed to enhancing your online visibility & driving traffic, resulting in increased leads.</p>
                </div>
            </div>
            {/* <!-- Digital Marketing --> */}
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="sk__iconbox">
                    <a class="sk__iconbox-icon-link" href="digital-marketing.php">
                        <span class="sk__flex-center sk__iconbox-icon"><span class="icon-line-chart sk__gradient-fancy-text"></span></span>
                        <span class="sk__iconbox-icon-dash"></span>
                    </a>
                    <a class="sk__iconbox-text-link gradient-links-bright" href="digital-marketing.php">
                        <h5>Digital Marketing</h5>
                    </a>
                    <p>Our services ensure your business stays ahead of the competition, reaching the right audience, and achieving measurable success.</p>
                </div>
            </div>
            {/* <!-- E-commerce --> */}
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="sk__iconbox">
                    <a class="sk__iconbox-icon-link" href="e-commerce.php">
                        <span class="sk__flex-center sk__iconbox-icon"><span class="icon-cart sk__gradient-fancy-text"></span></span>
                        <span class="sk__iconbox-icon-dash"></span>
                    </a>
                    <a class="sk__iconbox-text-link gradient-links-bright" href="e-commerce.php">
                        <h5>E-commerce</h5>
                    </a>
                    <p style={{textAlign: 'justify'}}>Choose us for your eCommerce needs, and experience a seamless blend of cutting-edge technology and user-centric design, driving higher conversions and customer satisfaction.</p>
                </div>
            </div>
            {/* <!-- Logo Making --> */}
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="sk__iconbox">
                    <a class="sk__iconbox-icon-link" href="logo-making.php">
                        <span class="sk__flex-center sk__iconbox-icon"><span class="icon-fire2 sk__gradient-fancy-text"></span></span>
                        <span class="sk__iconbox-icon-dash"></span>
                    </a>
                    <a class="sk__iconbox-text-link gradient-links-bright" href="logo-making.php">
                        <h5>Logo Making</h5>
                    </a>
                    <p style={{textAlign: 'justify'}}>Our team's creative flair and attention to detail result in logos that not only visually represent your business but also convey its values and identity effectively.</p>
                </div>
            </div>
            {/* <!-- Admin Panel --> */}
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="sk__iconbox">
                    <a class="sk__iconbox-icon-link" href="admin-panel.php">
                        <span class="sk__flex-center sk__iconbox-icon"><span class="icon-shield1 sk__gradient-fancy-text"></span></span>
                        <span class="sk__iconbox-icon-dash"></span>
                    </a>
                    <a class="sk__iconbox-text-link gradient-links-bright" href="admin-panel.php">
                        <h5>Admin Panel</h5>
                    </a>
                    <p style={{textAlign: 'justify'}}>Our admin panel solutions are designed to streamline your operations, providing you with the tools and insights necessary to make informed decisions and achieve operational excellence.</p>
                </div>
            </div>
        </div>
    </div>
</section>

				{/* <!-- /.sk__services-section --> */}


				<section class="sk__deco-row-section">
					<div class="container-fluid">
						<div class="row sk__deco-row dark-shade-7-bg dark-shade-5-border"></div>
					</div>
				</section>


				{/* <!-- Skills Section
				================================================== --> */}
				<section id="skills" class="sk__skills sk__py-m">

					<div class="container sk__supercontainer">
						{/* <!-- Section Header --> */}
						<div class="row sk__heading-spacer-l">
							<div class="col-12 col-xl-10 offset-xl-1 text-center">
								<h1 class="h1-small mb-3 pt-4 pb-1">Our Focus</h1>
								<p class="p-v2 skills-subhead">Our unwavering focus on your success means that every decision, strategy, and solution is tailored to your unique goals and challenges.</p>
							</div>
						</div>

						<div class="row sk__skills-row">
							<div class="col-12">
								<div class="sk__skill-wrap">
									<p class="sk__skill-name sk__skill-el">Quality Brand Building</p>
									<div class="sk__skill-area sk__skill-el">
										<div class="sk__skill-bar sk__gradient-back-v1"></div>
									</div>
									<p class="sk__skill-number sk__skill-el"><span class="sk__counterskills">90</span><span>%</span></p>
								</div>
								<div class="sk__skill-wrap">
									<p class="sk__skill-name sk__skill-el">Graphic & Web Design</p>
									<div class="sk__skill-area sk__skill-el">
										<div class="sk__skill-bar sk__gradient-back-v1"></div>
									</div>
									<p class="sk__skill-number sk__skill-el"><span class="sk__counterskills">95</span><span>%</span></p>
								</div>
								<div class="sk__skill-wrap">
									<p class="sk__skill-name sk__skill-el">Marketing & Social</p>
									<div class="sk__skill-area sk__skill-el">
										<div class="sk__skill-bar sk__gradient-back-v1"></div>
									</div>
									<p class="sk__skill-number sk__skill-el"><span class="sk__counterskills">92</span><span>%</span></p>
								</div>
								<div class="sk__skill-wrap">
									<p class="sk__skill-name sk__skill-el">On Time Delivery</p>
									<div class="sk__skill-area sk__skill-el">
										<div class="sk__skill-bar sk__gradient-back-v1"></div>
									</div>
									<p class="sk__skill-number sk__skill-el"><span class="sk__counterskills">100</span><span>%</span></p>
								</div>
								<div class="sk__skill-wrap">
									<p class="sk__skill-name sk__skill-el">Logo Creation</p>
									<div class="sk__skill-area sk__skill-el">
										<div class="sk__skill-bar sk__gradient-back-v1"></div>
									</div>
									<p class="sk__skill-number sk__skill-el"><span class="sk__counterskills">99</span><span>%</span></p>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col sk__features-subtext mt-4 mt-md-3">
								<div class="features-subtext-deco"></div>
								<p class="p-xs text-center text-sm-end features-subtext-p">We guarantee 100% client satisfaction. <br/>
									Our commitment is unwavering. <br/>
									Your happiness is our priority.</p>
								</div>
						</div>

						{/* <!-- <div class="row mt-5">
							<div class="col text-center text-sm-start">
								<h4 class="h4-dark h4-shadow">Us & The People We've Worked With.</h4>
								<span class="fat-divider dark-shade-4-bg"></span>
							</div>
						</div>

						<div class="row sk__testimonials text-center text-sm-start"> 
							<div class="col-12 col-lg-6 d-flex align-items-center sk__testimonial-container">
								<div class="sk__testimonial-img sk__image-back-cover sk__testimonial-img-1"></div>
								<div class="sk__testimonial-txt">
									<p class="p-xs mw-320">Salvia vape blue bottle bespoke wolf celiac quinoa cloud bread letterpress hammock photo booth. Palo santo vexillologist venmo shaman pitchfork tote bag.</p>
									<h5>Lana Starlords</h5>
									<p>Our CEO & Founder</p>
								</div>
							</div>
							<div class="col-12 col-lg-6 d-flex align-items-center sk__testimonial-container">
								<div class="sk__testimonial-img sk__image-back-cover sk__testimonial-img-2"></div>
								<div class="sk__testimonial-txt">
									<p class="p-xs mw-320">Salvia vape blue bottle bespoke wolf celiac quinoa cloud bread letterpress hammock photo booth. Palo santo vexillologist venmo shaman pitchfork tote bag.</p>
									<h5>Mary J. Blidge</h5>
									<p>Laravel Developerette</p>
								</div>
							</div>
						</div>--> */}
						
					</div>

				</section>
				{/* <!-- /.sk__skills --> */}


				{/* <!-- <section class="sk__deco-row-section">
					<div class="container-fluid">
						<div class="row sk__deco-row dark-shade-7-bg dark-shade-5-border"></div>
					</div>
				</section> --> */}


				{/* <!-- CTA Section (Warp CTA)
				================================================== --> */}
				<section class="sk__cta-warp position-relative sk__image-back-cover">
					<div class="container sk__powercontainer">
						<div class="row">
							<div class="col text-center">
								<div class="sk__warped-text-wrapper sk__flex-center">
									<span class="sk__warped-text">GET STARTED NOW</span>
								</div>
								<h3><strong>CHOOSE YOUR TEMPLATE</strong></h3>
								<a class="btn btn-lg btn-outline-light sk__warped-button" href="template.php" role="button">VIEW TEMPLATES -&gt;</a>
							</div>
						</div>
					</div>
				</section>


				{/* <!-- Contact Us Section
				================================================== --> */}
				<section id="contact-us" class="sk__contact-us sk__py-m sk__parallax-background-section sk__flex-center-y">
					<div class="sk__parallax-background-element sk__absolute sk__image-back-cover"></div>
					<div class="sk__tint sk__absolute"></div>
					<div class="container sk__powercontainer">
						{/* <!-- Section Header --> */}
						<div class="row sk__contact-info sk__inner-header text-center">
							<div class="col-12 col-lg-10 offset-lg-1">
								<h1 class="h1-small">Let's Start Creating</h1>
								<p class="p-v2">Want to make your business online but don't know where to start? Embark on a creative journey with us, where your ideas come to life through innovative solutions and collaborative expertise.</p>
							</div>
						</div>
						<div class="row">
							{/* <!-- Contact Form --> */}
							<div class="col-12 col-lg-10 offset-0 offset-lg-1 sk__contact-form-col d-flex justify-content-end">
								<div class="sk__contact-right text-center text-sm-start">

									{/* <!-- Preview Only Static Form --> */}
									<form class="sk__form sk__contact-form" action="forms/contact.php" method="post" role="form" id="myform">
										<div class="form-group">
											<input type="text" name="name" id="name" placeholder="Name*" tabindex="1" />
										</div>
										<div class="form-group">
											<input type="email" name="email" id="email" placeholder="Email*" tabindex="2" />
										</div>
										<div class="form-group">
											<input type="tel" name="phone" id="phone" placeholder="phone*" tabindex="2" />
										</div>
										<div class="form-group">
											<input type="text" name="subject" id="subject" placeholder="subject*" tabindex="2" />
										</div>
										<div class="form-group">
											<textarea name="body" id="body" placeholder="body*" tabindex="3"></textarea>
										</div>
										<button type="submit" onclick="sendEmail()" value="Send an Email" formtarget="_blank" tabindex="4">Submit</button>
									</form>
								</div>
							</div>
						</div>
					</div>
					{/* <script src="https://code.jquery.com/jquery-3.6.1.min.js"></script>
      				<script>
					function sendEmail(){
						var name = $("#name");
						var email = $("#email");
						var phone = $("#phone")
						var subject = $("#subject");
						var body = $("#body");

						if(isNotEmpty(name) && isNotEmpty(email) && isNotEmpty(subject) && isNotEmpty(body)){
						$.ajax({
							url : 'sendEmail.php',
							method : 'POST',
							dataType: 'json',
							data:{
							name : name.val(),
							email: email.val(),
							subject : subject.val(),
							body : body.val()
							},success: function(response){
							$('#myform')[0].reset();
							}
						});
						}
					}
					function isNotEmpty(caller){
						if(caller.val() == ""){
							caller.css('border','1px solid red');
							return false;
						}
						else{
							caller.css('border','');
							return true;
						}
						}
					</script> */}
				</section>
				{/* <!-- /.sk__contact-us --> */}


				<Footer />
			


				{/* <!-- Helper div for inserting before scripts
				================================================== --> */}
				<div class="sk__body-end"></div>

			</div>
			{/* <!-- /#smooth-content --> */}

		</div>
		{/* <!-- /#smooth-wrapper --> */}

	</main>
	{/* <!-- /main#primary.site-main --> */}

</div>
	
)
}
