import React from 'react'

export default function footer() {
  return (
	<>

				<footer class="dark-shade-2-bg position-relative">

					<div class="footer-background-container sk__absolute">
						<div class="sk__gradient-background-tint footer-background sk__absolute"></div>
					</div>
					
					<div class="container sk__supercontainer position-relative">

						<div class="row footer-top">
							<div class="col-12 col-sm-6 top-footer-logo">
								<img src="assets/images/logo-name.png" alt="Website logo for the footer."/>
							</div>
							<div class="col-12 col-sm-6 top-footer-tagline">
								<h5 class="h5-elegant">ADVANCEMENT IN <strong>DESIGN</strong></h5>
							</div>
						</div>

						<span class="divider sk__subtle-divider"></span>

						<div class="row footer-main text-center text-sm-start">
							<div class="col-md-12 col-lg-6 footer-main-large-col mb-4 mb-lg-0">
								<div class="fancy-gradient-text-box">
									<h3 class="h3-elegant sk__gradient-fancy-text">Start your journey now.</h3>
								</div>	
								<p class="p-v2 mw-440">Begin your transformative journey with us today, where your goals and aspirations meet our expertise and dedication.</p>
								<h5>Revanoid</h5>
								<p class="p-v2 mw-440"><span>1043A, Mutu Tower,Sathy Road,Near Surya Hospital,</span><br/><span>Ganapathy,Coimbatore - 641006, </span><br/><span>+91 63-7973-4223 </span><br /><span>info@revanoid.com</span></p>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-3 footer-main-small-col widget_nav_menu">
								<h5>Pages</h5>
								<ul>
									<li>
										<a href="about-us.php" class="footer-main-links gradient-links">About Us&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--&gt;</a>
									</li>
									<li>
										<a href="services.php" class="footer-main-links gradient-links">Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--&gt;</a>
									</li>
									<li>
										<a href="template.php" class="footer-main-links gradient-links">Templates&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--&gt;</a>
									</li>
									{/* <!--<li>-->
									<!--	<a href="#" class="footer-main-links gradient-links">Portfolio&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>-->
									<!--</li>--> */}
									<li>
										<a href="contact-us.php" class="footer-main-links gradient-links">Contact Us&nbsp;&nbsp;&nbsp;&nbsp;--&gt;</a>
									</li>
								</ul>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-3 footer-main-small-col">
								<div class="row">
									<div class="col">
										{/* <!-- Footer Social Icons Menu --> */}
										<section class="footer-socials-section">
											<h5>Follow Us & Stay Informed</h5>
											<div class="footer-socials-inner">
												<div class="footer-socials">
												<a class="social-icons" href="https://youtu.be/AfqMJOfHd8U?si=g9aRN300eI3JboJE" target="_blank" rel="noreferrer"><span><span class="icon-youtube1"></span></span></a>
												<a class="social-icons" href="https://www.linkedin.com/company/revanoid/" target="_blank" rel="noreferrer"><span><span class="icon-linkedin1"></span></span></a>
												<a class="social-icons" href="https://www.instagram.com/revanoid._/" target="_blank" rel="noreferrer"><span><span class="icon-instagram1"></span></span></a>
												<a class="social-icons" href="https://wa.link/6i6uku" target="_blank" rel="noreferrer"><span><span class="icon-whatsapp1"></span></span></a>
												</div>
											</div>
										</section>
									</div>
								</div>
								<div class="row">
									<div class="col">
										{/* <!-- Widget - Subscribe
										================================================== --> */}
										<div class="widget custom_subscribe_widget">
											<div class="sk__widget-inner">
												
												{/* <!-- Preview Only Static Form --> */}
												<form class="sk__form sk__subscribe-form">
													<div class="form-group">
														<input type="email" name="the_email" placeholder="Enter email address*" tabindex="1"/>
													</div>
													<button type="submit" tabindex="2">SUB</button>
												</form>

											</div>
										</div>
										{/* <!-- /.custom_subscribe_widget --> */}
									</div>
								</div>
							</div>
						</div>

						<span class="divider sk__subtle-divider"></span>

						<div class="row footer-bottom">
							<div class="col-xs-12 col-sm-6 col-md-4 text-center text-sm-start">
								<h5>REVANOID</h5>
							</div>
							<div class="col-12 col-md-4 order-xs-3 order-sm-3 order-md-2 text-center text-sm-start text-md-center">
								<p class="p-footer-copyright">Copyright © 2023    <a href="https://www.revanoid.com/" target="_blank" rel="noreferrer">Revanoid.com</a></p>
							</div>
							<div class="col-xs-12 col-sm-6 col-md-4 order-sm-2 order-md-3 text-center text-sm-end">
								<a class="footer-bottom-right-links" href="privacy-policy.php" target="_blank" rel="noreferrer">Privacy</a>
								<a class="footer-bottom-right-links" href="terms-and-conditions.php" target="_blank" rel="noreferrer">Terms</a>
								<a class="footer-bottom-right-links" href="contact-us.php" target="_blank" rel="noreferrer">Contact</a>
							</div>
						</div>
					</div>

			</footer>
			
				</>
				)
}
